import React from 'react';
import { compose } from 'recompose';
import Layout from '../components/Layout';
import { withAuthorization, withEmailVerification } from '../utils/Session';
import Comments from '../components/Comments';

const condition = (authUser) => !!authUser;
const CommentsPage = compose(withEmailVerification, withAuthorization(condition))(Comments);

export default () => {
  return (
    <Layout>
      <CommentsPage />
    </Layout>
  );
};
