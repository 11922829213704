import React from 'react';

import ToolTip from '../Tooltip';

import style from './Label.module.scss';

const Label = (props: { label: string; htmlFor: string; toolTip?: string }) => {
  const { label, htmlFor, toolTip } = props;

  return (
    <label className={style.label} htmlFor={htmlFor}>
      {label}
      {toolTip ? <ToolTip toolTipText={toolTip} /> : null}
    </label>
  );
};

export default Label;
