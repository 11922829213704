import React from 'react';

import { withFirebase } from '../../utils/Firebase';
import Select from '../Input/Select';
import cn from 'classnames';
import Button from '../Buttons/PrimaryButton';
import Input from '../Input';
import { format } from 'date-fns';

import style from './Comments.scss';

const Comments = ({ firebase }) => {
  const [comments, setComments] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [values, setValues] = React.useState({});

  const updateComments = async () => {
    const wmpComments = [];

    await firebase.secondary.comments().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        wmpComments.push({ id: doc.id, ...doc.data() });
        console.log(`${doc.id} => `, doc.data());
      });
    });

    setComments(wmpComments?.sort((a, b) => (a.status > b.status ? 1 : -1)));
  };

  React.useEffect(() => {
    if (firebase?.secondary) {
      updateComments();
    }
  }, [firebase?.secondary]);

  const handleUpdateStatus = async (name, value) => {
    const id = name?.split('--')[0];
    const status = value;

    setIsLoading(true);

    await firebase.secondary.firestore.collection('comments').doc(id).update({ status });

    updateComments();
    setIsLoading(false);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const { commentFormal, response, responseFormal, id } = e.target;

    const tempComment = {
      ...comments.filter((obj) => {
        return obj.id === e.target[3].value;
      })[0],

      commentFormal: commentFormal.value,
      response: response.value,
      responseFormal: responseFormal.value,
      id: id.value,
    };

    await firebase.secondary.firestore.collection('comments').doc(id.value).update(tempComment);

    setIsLoading(false);
  };

  return (
    <div className={cn('row', style.comments)}>
      <div className="col-12">
        <h1>Whatsmypayment.com Comments</h1>
        <h2>Approved:</h2>
      </div>
      {comments.map((comment, index) => (
        <>
          <div key={index} className="col-12 col-md-6 col-xl-3">
            <div className={style.comment}>
              <>
                <p>
                  {comment.displayName} ({comment.email})
                </p>
                <p>
                  {`${comment.date && format(new Date(Number(comment.date)), 'LLL do, y')}`} (last
                  updated:{' '}
                  {`${comment.date && format(new Date(Number(comment.lastUpdate)), 'LLL do, y')}`})
                </p>
                <textarea>{comment.comment}</textarea>
              </>
              <div className="d-flex">
                <Select
                  label="Status:"
                  placeholder=""
                  name={`${comment.id}--status`}
                  onChange={handleUpdateStatus}
                  className={style.select}
                  value={comment.status}
                  options={[
                    { label: 'Deleted', value: 'deleted' },
                    { label: 'Approved', value: 'approved' },
                    { label: 'Declined', value: 'declined' },
                    { label: 'Pending', value: 'pending' },
                  ]}
                ></Select>
              </div>
              <form className={style.inputs} onSubmit={handleSubmit}>
                <Input
                  name="commentFormal"
                  value={values[comment.id]?.commentFormal}
                  labelName="Comment Formal:"
                  onChange={handleInputChange}
                  defaultValue={comment.commentFormal}
                />
                <Input
                  name="response"
                  value={values[comment.id]?.response}
                  defaultValue={comment.response}
                  labelName="Response:"
                  onChange={handleInputChange}
                />
                <Input
                  name="responseFormal"
                  value={values[comment.id]?.responseFormal}
                  defaultValue={comment.responseFormal}
                  labelName="Response Formal:"
                  onChange={handleInputChange}
                />
                <input type="hidden" name="id" value={comment.id} />
                <Button type="submit" data-id={comment.id} isLoading={isLoading}>
                  Update
                </Button>
              </form>
            </div>
          </div>
          {comments[index + 1] && comments[index]?.status !== comments[index + 1]?.status && (
            <div className="col-12">
              <h2>{comments[index + 1]?.status}:</h2>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default withFirebase(Comments);
